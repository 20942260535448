import React from "react";
import LayoutMain from "../layouts/LayoutMain";
import Seo from "../components/seo";
import { Result } from "antd";

const NotFoundPage = () => (
  <LayoutMain>
    <Seo title="404: Not found" />
    <Result
      status="warning"
      title="We couldn't find this flowchart"
      subTitle="Are you sure you are using the correct URL?"
      style={{ paddingTop: "104px" }}
    />
  </LayoutMain>
);

export default NotFoundPage;
