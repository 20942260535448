import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, navigate } from "gatsby";
import { PageHeader, Affix } from "antd";
import PageHeaderMenu from "../components/ui/PageHeaderMenu";
import Footer from "../components/sections/Footer.js";
import { ShareAltOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import "../styles/layout.scss";

const LayoutMain = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryMain {
        site {
          siteMetadata {
            title
            company
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet>
          <script src="/js/iubenda.js" type="text/javascript" />
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/tcf/stub-v2.js"
          ></script>
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/ccpa/stub.js"
          ></script>
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/iubenda_cs.js"
            charset="UTF-8"
            async
          ></script>
          <script src="/js/crisp.js" type="text/javascript" />
        </Helmet>
        {/* <Alert
          type="success"
          banner
          message={
            <>
              <Text style={{ marginTop: "1px" }}>
                Introducing our new plans and pricing{" "}
                <span style={{ margin: "0 3px" }}>🎉</span> Click{" "}
                <Link to="/plans">here</Link>
              </Text>
            </>
          }
          style={{
            textAlign: "center",
            fontSize: "11px",
            borderBottom: "1px solid #ced4da",
          }}
          icon={<></>}
        /> */}
        <Affix style={{ zIndex: "50000", width: "100%", position: "absolute" }}>
          <PageHeader
            style={{
              width: "100%",
              zIndex: "50000",
              backgroundColor: "#fff",
              borderBottom: "1px solid #ced4da",
              // padding: "0 12px",
              height: "67px",
            }}
            onBack={() => navigate("/")}
            backIcon={
              <ShareAltOutlined style={{ transform: "rotate(90deg)" }} />
            }
            subTitle={
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                onKeyDown={() => navigate("/")}
                role="link"
                tabIndex={0}
              >
                {data.site.siteMetadata.title}
              </span>
            }
            extra={[<PageHeaderMenu />]}
          />
        </Affix>
        {children}
        <Footer />
      </>
    )}
  />
);

LayoutMain.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutMain;
